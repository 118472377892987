<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-title>
                <h3>
                  تعديل طلب حاجة
                </h3>
              </b-card-title>
              <!-- Spacer -->
              <hr class="invoice-spacing">
              <b-card-body>
                <div class="mt-2">
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        label=" نوع الحاجة"
                        label-for="payment-method"
                      >
                        <v-select

                          v-model="Form.type"
                          :options="orderOption"
                          :reduce="(val) => val.value"
                          input-id="payment-method"
                          class="payment-selector"
                          :clearable="false"
                      
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

                </div>

                <!-- Invoice Client & Payment Details -->

                <!-- Items Section -->
                <b-card-body
                  v-if="Form.type==0"
                  class="invoice-padding form-item-section"
                >
                  <h4>تفاصيل الطلب</h4>
                  <div
                    ref="form"
                    class="repeater-form"
                  >
                    <b-row
                      v-for="(item, index) in Form.order_items"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >

                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col cols="12">
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="5"
                            >
                              <label>
                                المادة
                              </label>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="3"
                            >
                              <label>
                                الكمية
                              </label>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label>
                                الواحدة
                              </label>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label>
                                ملاحظة
                              </label>
                            </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="5"
                            >
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-group
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <label class="d-inline d-lg-none">المادة</label>
                                  <v-select
                                    v-model="item.item_id"
                                    :dir="
                                      $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                    "
                                    :options="ItemData"
                                    label="name"
                                    :clearable="false"
                                    :reduce="(val) => val.id"
                                    class="mb-2 item-selector-title"
                                    placeholder="Select Item"
                                  />
                                </b-form-group>
                                <b-form-invalid-feedback
                                  :state="errors.length > 0 ? false : null"
                                >
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="3"
                            >
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-group
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <label class="d-inline d-lg-none">الكمية</label>
                                  <b-form-input
                                    v-model="item.quantity"
                                    type="number"
                                    class="mb-2"
                                  />
                                </b-form-group>
                                <b-form-invalid-feedback
                                  :state="errors.length > 0 ? false : null"
                                >
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-group
                                  label-for="notes"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <label
                                    class="d-inline d-lg-none"
                                  >الواحدة</label>
                                  <v-select
                                    v-model="item.unit_id"
                                    :dir="
                                      $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                    "
                                    :options="ubitsOption"
                                    label="name"
                                    :clearable="false"
                                    :reduce="(val) => val.id"
                                    class="mb-2 item-selector-title"
                                    placeholder="Select Item"
                                  />

                                </b-form-group>
                                <b-form-invalid-feedback
                                  :state="errors.length > 0 ? false : null"
                                >
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                            <!-- <b-col
                              cols="12"
                              lg="2"
                            >
                              <validation-provider
                                #default="{ errors }"
                              >
                                <b-form-group
                                  label-for="notes"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <label
                                    class="d-inline d-lg-none"
                                  >ملاحظة</label>
                                  <b-form-input
                                    v-model="item.notes"
                                    type="text"
                                    class="mb-2"
                                  />
                                </b-form-group>
                                <b-form-invalid-feedback
                                  :state="errors.length > 0 ? false : null"
                                >
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-col> -->
                            <b-col
                              md="3"
                              xl="4"
                            >
                              <validation-provider
                                #default="{ errors }"
                                rules=""
                              >
                                <b-form-group
                                  label-for="recive_date"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <label> تاريخ الاستلام </label>
                                  <flat-pickr
                                    v-model="item.recive_date"
                                    class="form-control"
                                    placeholder="حدد تاريخ "
                                  />
                                </b-form-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                            <b-col
                              cols="2"
                              lg="2"
                            >

                              <b-form-group
                                label-for="notes"
                              >
                                <label> نوع مكان التسليم </label>

                            
  <v-select

                          v-model="item.location_type"
                          :options="LocationType"
                          :reduce="(val) => val.value"
                          input-id="payment-method"
                          class="payment-selector"
                          @input="item.recive_location=''"
                          :clearable="false"
                      
                        />
                              </b-form-group>

                            </b-col>
                             <b-col
                              cols="2"
                              lg="2"
                              v-if="item.location_type==1"
                            >

                              <b-form-group
                                label-for="notes"
                              >
                                <label>مكان التسليم</label>

                                <b-form-input
                                  v-model="item.recive_location"
                                  type="text"
                                  class="mb-2"
                                />
 
                              </b-form-group>

                            </b-col>
<b-col
                              cols="2"
                              lg="2"
                              v-if="item.location_type==0"
                            >

                              <b-form-group
                                label-for="notes"
                              >
                                <label> المستودع</label>

                            
                                 <v-select
                                  v-model="item.recive_location"
                                  :options="warehouseOption"

                                  label="name"
                                  :clearable="false"
                                
                                
                                />
 
                              </b-form-group>

                            </b-col>

                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <validation-provider
                                #default="{ errors }"
                              >
                                <b-form-group
                                  label-for="notes"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <label>ملاحظة</label>
                                  <b-form-input
                                    v-model="item.notes"
                                    type="text"
                                    class="mb-2"
                                  />
                                </b-form-group>
                                <b-form-invalid-feedback
                                  :state="errors.length > 0 ? false : null"
                                >
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                          </b-row>
                          <div
                            class="d-flex flex-column justify-content-between border-left py-50 px-25"
                          >
                            <feather-icon
                              v-if="index != 0"
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItem(index)"
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewItemInItemForm"
                  >
                    <span>
                      اضافة مادة اخرى
                    </span>
                  </b-button>
                </b-card-body>
                <b-row>
                  <!-- Invoice Description: Total -->
                  <b-col md="4">
               
                    <tags-input
                      v-model="Form.details"
                      element-id="value"
                      :existing-tags="tagList"
                      :typeahead="true"
                    />
                    <div />
                  </b-col>
                  <!-- Spacer -->

                  <!-- Note -->
                  <b-col
                    cols="6"
                    lg="5"
                  >
                    <label class="d-inline d-lg-none">ملاحظة</label>
                    <b-form-textarea
                      v-model="Form.notes"
                      placeholder="تفاصيل طلب غير موجود"
                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
              <hr class="invoice-spacing">
              <b-col
                class="border-Primary mb-10"
                md="12"
                xl="4"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  type="submit"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50 mb-10"
                  />
                  <span class="align-middle"> تعديل الطلب</span>
                </b-button>
              </b-col>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
      <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  bCardTitle,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
  BFormTag,
  BFormSelect,
  BFormTags,
} from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import VoerroTagsInput from '@voerro/vue-tagsinput'
import router from '@/router'

import financeStoreModule from './financeStoreModule.js'
import store from '@/store'

export default {
  components: {
    BRow,
    bCardTitle,
    financeStoreModule,
    BCol,

    'tags-input': VoerroTagsInput,
    BFormSelect,
    BCard,
    BCardBody,
    required,
    BFormTag,
    BFormTags,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'
    const orderOption = [
      { label: 'سلعة ', value: 0 },
      { label: 'خدمة ', value: 1 },
      { label: 'صيانة ', value: 2 },

    ]
    const warehouseOption = ref([])
    const LocationType = [
      { label: 'مستودع  ', value: 0 },
      { label: 'مكان اخر ', value: 1 },
    

    ]

    const addNewItemInItemForm = () => {
     Form.value.order_items.push(
        JSON.parse(JSON.stringify(itemFormBlankItem)),
      )
    }
    const removeItem = index => {
      Form.value.order_items.splice(index, 1)
    }
    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })

    const ItemData = ref([])
    const paymentDetails = ref({})
    const ubitsOption = ref([])
    store.dispatch('app-finance/GetUnits').then(response => {
      // console.log('ubitsOption,',ubitsOption);
      ubitsOption.value = response
    })
    store.dispatch('app-finance/GetWareHouse').then(response => {
      response.forEach(el => {
        warehouseOption.value.push({
          id: el.id,
          name: el.name,
        })
      })
    })
    store.dispatch('app-finance/GetItem').then(response => {
      // console.log(response);
      ItemData.value = response
    })

    const selectedTags = [

    ]
    const tagList = ref([])
    const itemFormBlankItem = {
      item_id: '',
      quantity: 1,
      notes: '',
      unit_id: '',
    }
    const Form = ref({
      notes: '',
      type: '',
      details: '',
      order_items: [
        {
          item_id: '',
          quantity: 1,
          notes: '',
          unit_id: '',
          recive_date: '',
          recive_location: '',

        },
      ],
    })
    const getDetails = id => {
      store.dispatch('app-finance/getMaterialOrder', id).then(response => {
        Form.value = response.data
    //    console.log(Form.value.details)
        // const details = response.data.details.match(/"([^"]*)"/)[1]

// console.log(details);
        // Splitting the comma-separated values
        const values = response.data.details.split(',')


        // Creating an array of objects
   Form.value.details  = values.map(value => {
          const pair = value.split(':')
          return { key: pair[0], value: pair[0] }
        })
    
      })
      

    }
    getDetails(router.currentRoute.params.id)
    store.dispatch('app-finance/getDetailsItem').then(response => {
      response.forEach(el => {
        tagList.value.push({
          key: el,
          value: el,
        })
      })
    })
    const onSubmit = () => {
    //  console.log(Form.value);

      if (Form.value.type != 0) {
        delete Form.value.order_items
      }
      if(Form.value.details != null){
        if (Form.value.details.length > 0) {
       //   console.log("Form.value.details.length")
      Form.value.details = Form.value.details.map(el => el.value).join(',')
     } 

      }
        
     store.dispatch('app-finance/UpdateRequestItem', Form.value).then(response => {
        Form.value.notes = ''
        Form.value.type = '',
        Form.value.details = ''
        Form.value.order_items = [{
          item_id: '',
          quantity: 1,
          notes: '',
          unit_id: '',
        }]
        resetForm()
        Vue.swal({
          title: '',
          text: 'تمت أضافة الطلب بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }

    const paymentMethods = ['Bank Account', 'PayPal', 'UPI Transfer']
    const requestFor = ref(null)
    return {
      ubitsOption,
      requestFor,
      selectedTags,
      getDetails,
      onSubmit,
      refFormObserver,
      resetForm,
      getValidationState,
      addNewItemInItemForm,
      removeItem,
      tagList,
      ItemData,
      Form,
      orderOption,
      itemFormBlankItem,
      paymentMethods,
      LocationType,
      warehouseOption,
    }
  },
  data() {
    return {
      options: ['Apple', 'Orange', 'Banana', 'Lime', 'Peach', 'Chocolate', 'Strawberry'],
      value: [],
      newTag: '',
    }
  },
  methods: {
    addTag(tag) {
      if (!this.selectedTags.includes(tag)) {
        this.selectedTags.push(tag)
      }
    },
    removeTag(index) {
      this.selectedTags.splice(index, 1)
    },
    addNewTag() {
      const newTag = this.newTag.trim()
      if (newTag !== '' && !this.value.includes(newTag)) {
        this.value.push(newTag)
        if (!this.options.includes(newTag)) {
          this.options.push(newTag)
        }
        this.newTag = '' // Clear input after adding new tag
      }
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.form-item-action-col {
  width: 27px;
}
b-card-title {
  text-align: center;
  margin-top: 12px;
}
.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}
.border-Primary.mb-10.col-md-12.col-xl-4 {
  margin-bottom: 21px;
}
.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
